<template>
    <b-card>
        <validation-observer ref="changPasswordForm">
            <!-- form -->
            <b-form>
                <b-row>
                    <!-- old password -->
                    <b-col class="offset-3" md="6">
                        <b-form-group
                            label="Old Password"
                            label-for="account-old-password">
                            <validation-provider
                                #default="{ errors }"
                                name="Old Password"
                                rules="required">
                                <b-input-group class="input-group-merge">
                                    <b-form-input
                                        id="account-old-password"
                                        v-model="passwordValueOld"
                                        :type="passwordFieldTypeOld"
                                        name="old-password"
                                        placeholder="Old Password"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="passwordToggleIconOld"
                                            class="cursor-pointer"
                                            @click="togglePasswordOld"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                    </b-col>
                    <!--/ old password -->
                    <!-- new password -->
                    <b-col class="offset-3" md="6">
                        <b-form-group
                            label="New Password"
                            label-for="account-new-password"
                        >
                            <b-input-group class="input-group-merge">
                                <b-form-input
                                    id="account-new-password"
                                    v-model="newPasswordValue"
                                    :type="passwordFieldTypeNew"
                                    name="new-password"
                                    placeholder="New Password"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                        :icon="passwordToggleIconNew"
                                        class="cursor-pointer"
                                        @click="togglePasswordNew"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!--/ new password -->

                    <!-- retype password -->
                    <b-col class="offset-3" md="6">
                        <b-form-group
                            label="Confirm New Password"
                            label-for="account-retype-new-password"
                        >
                            <b-input-group class="input-group-merge">
                                <b-form-input
                                    id="account-retype-new-password"
                                    v-model="RetypePassword"
                                    :type="passwordFieldTypeRetype"
                                    name="retype-password"
                                    placeholder="New Password"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                        :icon="passwordToggleIconRetype"
                                        class="cursor-pointer"
                                        @click="togglePasswordRetype"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!--/ retype password -->

                    <!-- buttons -->
                    <b-col class="offset-3" cols="12">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="mt-1 mr-1"
                            variant="primary"
                            @click.prevent="changePassword">
                            Save changes
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            class="mt-1"
                            type="reset"
                            variant="outline-secondary"
                        >
                            Reset
                        </b-button>
                    </b-col>
                    <!--/ buttons -->
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import {checkRes} from "@core/services/helper";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required, email} from '@validations'
import Mixin from "@core/services/mixin";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BInputGroupAppend,
        // validations
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            passwordValueOld: '',
            newPasswordValue: '',
            RetypePassword: '',
            passwordFieldTypeOld: 'password',
            passwordFieldTypeNew: 'password',
            passwordFieldTypeRetype: 'password',
            required,
        }
    },
    computed: {
        passwordToggleIconOld() {
            return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconNew() {
            return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconRetype() {
            return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        togglePasswordOld() {
            this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
        },
        togglePasswordNew() {
            this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
        },
        togglePasswordRetype() {
            this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
        },
        changePassword() {
            this.$refs.changPasswordForm.validate().then(success => {
                if (success) {
                    useJwt.changePasswordIsp({
                        old_password: this.passwordValueOld,
                        new_password: this.newPasswordValue,
                        confirm_password: this.RetypePassword,
                    }).then(response => {
                        // @ts-ignore
                        if (checkRes(response.data.code)) {
                            this.$refs.changPasswordForm.reset()
                            this.passwordValueOld = ''
                            this.newPasswordValue = ''
                            this.RetypePassword = ''
                            this.$router.push({ name: 'isp-change-password' })
                                .catch(error => {
                                this.toastMessage('success','Change Password',response)
                            })
                            this.$refs['changPasswordForm'].resetFields();
                        } else {
                            this.toastMessage('warning','Change Password', response)
                        }
                    })
                    .catch(error => {
                        this.toastMessage('danger','Change Password', error)
                    })
                }
            })
        },
    },
}
</script>
